<template>
  <div class="relative min-h-screencalc-minus-nav flex flex-col" :class="templateClass">
    <LoaderMainCss/>

    <ClientOnly>
      <NavigationLHS/>
      <NavigationLHSCollapsed/>
      <NavigationTop/>
      <PanelMessageRotator
        v-if="showRotator && isUserLogged"
        class="z-[25] lg:z-[9]"
        :class="{ '!fixed top-0 left-0 right-0 h-[22px]': (!isMobileNavHidden && isMobileNavOpen) }"
        :slow="inGame"
      />
      <NavigationTopMobile/>
    </ClientOnly>

    <div :class="wrapperClass">
      <slot/>
    </div>

    <ClientOnly>
      <ChatDiscordMain v-if="discordChatEnabled"/>
    </ClientOnly>
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default defineComponent({
  inject: ['mq',],
  data() {
    return {
      updateTrigger: false,
    };
  },
  computed: {
    ...mapState(useUiStore, ['discordChatEnabled', 'chatState', 'navState', 'isMobileNavHidden', 'isMobileNavOpen', 'templateClass', 'messageRotatorHidden',]),
    ...mapState(useAuthStore, ['isUserLogged',]),
    wrapperClass() {
      const classes = [];
      if (this.navState === 'full') {
        classes.push('lg:pl-[--nav-left-width]');
      } else if (this.navState === 'mini') {
        classes.push('lg:pl-[--nav-left-mini-width]');
      }
      if (!['hidden', 'disabled',].includes(this.chatState)) {
        classes.push('xl:pr-[--chat-desktop-width]');
      }
      return classes.join(' ');
    },
    inGame() {
      return this.$route.meta?.inGame;
    },
    showRotator() {
      if (this.messageRotatorHidden) {
        return false;
      }

      const hasExcludedRoutes = this.$route.name.includes('live-games-id') || this.$route.name.includes('games-id');
      return (!this.inGame || this.mq.lgPlus) && !hasExcludedRoutes;
    },
  },
  mounted() {
    // For some reason the wrapper class is not updated when the navState is changed on page load. This is a workaround.
    this.updateWrapperClass();
  },
  methods: {
    updateWrapperClass() {
      this.updateTrigger = !this.updateTrigger;
    },
  },
});
</script>
